<!--
 * @Author: wjc
 * @Date: 2023-11-01 16:03:05
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-10-12 16:41:42
 * @Description: 
-->
<template>
  <MRow :span="span" :gutter="$screen.higherThan('xs') ? 20 : null">
    <el-col :span="24">
      <ServicesList></ServicesList>
      <div
        class="relative flex items-center justify-between bg-bg-3 p-16px mb-48px h-88px lt-xs:flex-col lt-xs-justify-center lt-xs:h-auto lt-xs:hidden"
      >
        <div class="flex justify-between lt-xs:w-full lt-xs-none">
          <div class="flex items-center">
            <span class="i-custom-ggl"></span>
            <span class="text-primary-color font-s-18px font-600 ml-12px">公告栏</span>
          </div>
          <div
            v-if="$screen.higherThan('xs')"
            class="flex-center text-color-place font-s-14px cursor-pointer"
          >
            <MLink to="/content/912305321901290201">
              <span>查看更多</span>
              <span class="i-ep-d-arrow-right ml-4px wh-12px"></span>
            </MLink>
          </div>
        </div>
        <el-divider
          :direction="$screen.higherThan('xs') ? 'horizontal' : 'vertical'"
          class="mx-24px! lt-xs:my-12px!"
        ></el-divider>
        <div class="flex-1 w-full">
          <el-carousel direction="vertical" height="54px" indicator-position="none" class="w-full">
            <el-carousel-item v-for="item in noticeData" :key="item.id">
              <MLink
                :to="item.jumpLink || `/content/post/${item.id}?m=${item.classId2}`"
                :target="item.jumpLink ? '_blank' : ''"
              >
                <div class="font-600 text-color-1 font-s-18px">{{ item.pushTime }}</div>
                <div class="text-color-2 font-s-16px">{{ item.name }}</div>
              </MLink>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          v-if="!$screen.higherThan('xs')"
          class="flex-center text-color-place font-s-14px cursor-pointer"
        >
          <MLink to="/content/912305321901290201">
            <span>查看更多</span>
            <span class="i-ep-d-arrow-right ml-4px wh-12px"></span>
          </MLink>
        </div>
      </div>
      <el-row class="mb-48px lt-xs:mt-48px">
        <el-col :xs="24" :md="11">
          <el-carousel
            :height="isXs ? '311px' : '411px'"
            :indicator-position="isXs ? '' : ''"
            :arrow="isXs ? 'always' : 'hover'"
            class="bg-bg-4 rounded-4px max-w-538px"
          >
            <el-carousel-item v-for="item in carouselList" :key="item.id">
              <slot name="banner">
                <div class="relative h-full cursor-pointer">
                  <MLink
                    :to="
                      item.jumpLink ? item.jumpLink : `/content/post/${item.id}?m=${item.classId2}`
                    "
                    :target="item.jumpLink ? '_blank' : ''"
                  >
                    <img :src="item.img" class="wh-full object-contain" />
                    <div
                      class="absolute bottom-0 left-0 p-16px color-white w-full"
                      style="background: rgba(0, 0, 0, 0.5)"
                    >
                      {{ item.name }}
                    </div>
                  </MLink>
                </div>
              </slot>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <!-- 协会动态-专题活动 -->
        <el-col :xs="24" :md="13" class="md:mt-0 mt-48px xs:pl-35px">
          <el-radio-group
            v-model="sectorType"
            size="large"
            class="sector-group w-full"
            @change="onChangeSectorType"
          >
            <template v-for="item in sectorTypeList" :key="item.id">
              <el-radio-button :label="item.id" class="">{{ item.name }}</el-radio-button>
            </template>
          </el-radio-group>
          <div>
            <template v-for="item in sectorList" :key="item.id">
              <MLinkItem
                :to="item.jumpLink ? item.jumpLink : `/content/post/${item.id}?m=${sectorType}`"
                :jump="item.jumpLink"
                border
                :text="item.name"
                :date="item.pushTime"
              ></MLinkItem>
            </template>
            <el-empty v-if="sectorList.length === 0" description="暂无相关内容" />
            <div
              v-if="sectorListTotal > 6"
              class="float-right flex-center w-110px lt-xs-w-full text-color-place font-s-1.4rem cursor-pointer border-1px border-primary-color py-4px px-16px rounded-4px text-primary-color"
            >
              <MLink
                :to="`/content/${sectorMoreLink}`"
                class="text-primary-color w-full text-center"
              >
                <span>查看更多</span>
                <span class="i-ep-d-arrow-right ml-4px wh-12px"></span>
              </MLink>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="30" class="flex justify-between mb-24px">
        <!-- 政策法规，党建引领 -->
        <el-col :xs="24" :sm="12" :md="8" class="lt-xs:mb-16px">
          <Rules class="flex-1"></Rules>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" class="lt-xs:mb-16px">
          <PartyLine class="flex-1"></PartyLine>
        </el-col>
        <el-col :xs="24" :sm="24" :md="7">
          <div class="wrapper">
            <div v-for="item in typeList" :key="item.text" class="item">
              <MLink :to="item.link" class="flex flex-col items-center">
                <div :class="`i-custom-${item.icon}`" class="wh-38px"></div>
                <div class="text text-color-1 font-s-16px mt-8px">
                  {{ item.text }}
                </div>
              </MLink>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </MRow>
  <div class="bg-bg-3 wh-full">
    <MRow :span="span" :gutter="$screen.higherThan('xs') ? 20 : null">
      <el-col :span="24">
        <Company :list="xsEnterpriseData"></Company>
      </el-col>
    </MRow>
  </div>
</template>

<script setup lang="ts">
  import type { IInfo } from '~/models/activity'
  import type { EnterpriseCase } from '~/models/enterprise'
  import { typeList } from '~/constants'
  import { getEnterOpen, getInfoList } from '~/api/activity/info'
  import { getImagesBase64 } from '~/api/options'
  import { apiBase } from '~/constants'
  import ServicesList from './components/services-list.vue'
  import Company from './components/company.vue'
  import Rules from './components/rules.vue'
  import PartyLine from './components/party-line.vue'
  import { appName } from '~/constants'

  useHead({
    title: appName,
  })

  const $screen = useScreen()
  const isXs = computed(() => $screen.higherThan('xs'))
  const span = computed(() => $screen.span.value)
  const downloadUrl = computed(() => `${apiBase}/file/download`)
  const carouselList = ref<IInfo[]>([])
  const enterpriseData = ref([])
  const xsEnterpriseData = computed(() => {
    let list = $screen.higherThan('sm')
      ? arrayChunk<EnterpriseCase>(enterpriseData.value, 2)
      : arrayChunk<EnterpriseCase>(enterpriseData.value, 8)
    return list
  })
  const enterLoading = ref(false)
  const noticeData = ref<IInfo[]>([])
  const sectorType = ref('912305321901290201')
  const sectorTypeList = ref([
    {
      id: '912305321901290201', // 查询行业动态-协会动态
      more: '912305321901290201',
      name: '协会通知',
    },
    {
      id: '912305321901290202', // 查询行业动态-专题活动
      more: '912305321901290202',
      name: '政府通知',
    },
  ])
  const sectorList = ref<IInfo[]>([])
  const sectorListTotal = ref(0)
  const sectorMoreLink = computed(() => {
    const d = sectorTypeList.value.find((item) => item.id === sectorType.value)
    if (d) {
      return d.more
    }
    return ''
  })

  /** 获取轮播图列表 */
  const getCarouselList = async () => {
    const params = {
      isPage: true,
      carousel: 1,
      pageSize: 5,
      pageNum: 1,
    }
    const data = await getInfoList(params)
    if (data && data.data && data.data.list && Array.isArray(data.data.list)) {
      carouselList.value = data.data.list
        .sort((a, b) => b.top - a.top)
        .map((item) => {
          return {
            ...item,
            img: `${downloadUrl.value}/${item.backgroundImage}`,
          }
        })
      // const ids = carouselList.value
      //   .sort((a, b) => b.top - a.top)
      //   .map((item) => item.backgroundImage)
      // .join(',')
      // 用图片id获取图片二进制流，用于base64展示
      // if (ids) {
      //   getImagesBase64(ids).then((res) => {
      //     const data = res.data
      //     carouselList.value.forEach((item) => {
      //       const match = data.find((e) => e.fileId === item.backgroundImage)
      //       if (match) {
      //         item.img = 'data:image/png;base64,' + match.base64
      //       }
      //     })
      //   })
      // }
    }
  }

  // 获取企业展示数据
  const getEnterOpenData = () => {
    getEnterOpen()
      .then((res) => {
        if (res) {
          enterpriseData.value = res.data
        }
      })
      .finally(() => {
        enterLoading.value = false
      })
  }
  /** 获取数据 */
  const onGetInfoList = (id: string) => {
    getInfoList({
      pageNum: 1,
      pageSize: 3,
      isPage: true,
      classId1: id,
      pushTimeAtSort: 'desc',
    }).then((res) => {
      if (res.data && res.data.list) {
        noticeData.value = res.data.list
      }
    })
  }

  /** 获取列表数据 */
  const onSectorTypeList = (id: string) => {
    getInfoList({
      pageNum: 1,
      pageSize: 6,
      isPage: true,
      classId1: '912305321901290200',
      classId2: id,
    }).then((res) => {
      if (res.data && res.data.list) {
        sectorList.value = res.data.list
        sectorListTotal.value = res.data.total
      }
    })
  }

  const onChangeSectorType = (v: string) => {
    onSectorTypeList(v)
  }

  // 获取企业展示
  getEnterOpenData()
  // 获取公告栏-通知公告
  onGetInfoList('912305321901290200')
  // 获取行业资讯
  onSectorTypeList(sectorType.value)
  // 首页轮播图
  getCarouselList()
</script>

<style scoped lang="scss">
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 105px);
    gap: 8px;
    @screen lt-xs {
      grid-template-columns: repeat(3, 1fr);
    }
    .item {
      @apply flex-center text-center px-20px py-30px bg-bg-3 cursor-pointer rounded-4px lt-xs:px-10px lt-xs:py-20px;
      &:hover {
        .text {
          @apply text-primary-color;
          transition: 0.3s;
        }
      }
    }
  }
  :deep(.sector-group) {
    @apply flex justify-between;
    .el-radio-button {
      @apply flex-1;
      .el-radio-button__inner {
        @apply w-full;
      }
    }
  }
</style>
